import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const languages = ['en', 'fr', 'es', 'ar'];

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: 'en',
        resources: Object.fromEntries(
            languages.map(lang => [lang, { translations: require(`./locales/${lang}/translations.json`) }])
        ),
        ns: ['translations'],
        defaultNS: 'translations',
    })
    .catch(error => console.error(error));


i18n.languages = ['en', 'fr', 'es', 'ar'];

export default i18n;
