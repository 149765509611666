import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocale, getUiDirection } from './helper/Locale';
import Grid from '@mui/material/Unstable_Grid2';
import { useDispatch, useSelector } from "react-redux";

import { setTheme } from "./features/theme/themeSlice";

import './App.css';

import TrackForm from './components/TrackForm';
import LanguageSelector from './components/LanguageSelector';
import PoweredBy from './components/PoweredBy';

import '@fontsource/varela-round';
import '@fontsource-variable/open-sans';
import '@fontsource-variable/montserrat';

function App() {
    const { i18n } = useTranslation();
    const { theme }  = useSelector((store) => store.theme)
    const dispatch = useDispatch();
    const uiDirection = getUiDirection();

    useEffect(() => {
        i18n.changeLanguage(getLocale()).then(() => {
        }).catch(e => {
            console.error(e)
        });
    }, [i18n]);

    useEffect(() => {
        dispatch(setTheme());
    }, [dispatch]);

    return (
        <div className="App" style={{ backgroundColor: theme.backgroundColor }}>
            <Grid container spacing={1} style={{ width: '100%' }} dir={uiDirection}>
                <Grid item xs={12} dir={uiDirection}>
                    <TrackForm />
                </Grid>
                <Grid item xs={6} dir={uiDirection}>
                    <LanguageSelector />
                </Grid>
                <Grid item xs={6} dir={uiDirection}>
                    <PoweredBy />
                </Grid>
            </Grid>
        </div>
    );
}

export default App;
