import { createSlice } from '@reduxjs/toolkit';
import { darkenHex, isValidHex, setHex } from '../../helper/Theme.js'

const initialState = {
    theme: {
        backgroundColor: '#FFF',
        buttonTextColor: '#FFF',
        buttonBackgroundColor: '#038FFD',
        buttonBackgroundHoverColor: '#0977D9',
        primaryTextColor: '#000',
        secondaryTextColor: '#424348',
        footerTextColor: '#5F6D7B'
    }
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme: (state) => {
            const search = window.location.search;
            const params = new URLSearchParams(search);

            const backgroundColor = params.get('bg');
            const buttonTextColor = params.get('btn');
            const buttonBackgroundColor = params.get('btn_bg');
            const primaryTextColor = params.get('ptc');
            const secondaryTextColor = params.get('stc');
            const footerTextColor = params.get('ft');

            if (isValidHex(backgroundColor)) {
                state.theme.backgroundColor = setHex(backgroundColor);
            }

            if (isValidHex(buttonTextColor)) {
                state.theme.buttonTextColor = setHex(buttonTextColor);
            }

            if (isValidHex(buttonBackgroundColor)) {
                state.theme.buttonBackgroundColor = setHex(buttonBackgroundColor);
                state.theme.buttonBackgroundHoverColor = darkenHex(buttonBackgroundColor, 0.8);
            }

            if (isValidHex(primaryTextColor)) {
                state.theme.primaryTextColor = setHex(primaryTextColor);
            }

            if (isValidHex(secondaryTextColor)) {
                state.theme.secondaryTextColor = setHex(secondaryTextColor);
            }

            if (isValidHex(footerTextColor)) {
                state.theme.footerTextColor = setHex(footerTextColor);
            }
        }
    }
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
