import React from 'react';
import { useTranslation } from 'react-i18next';
import { getLocale, getUiDirection } from '../helper/Locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";

import '../style/LanguageSelector.css'

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const { theme }  = useSelector((store) => store.theme);
    const uiDirection = getUiDirection();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const langPickerParam = params.get('lp') === 'true';

    const changeLanguage = e => {
        const newLanguage = e.target.value;

        // Update the URL parameter 'l' to be equal to newLanguage
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('l', newLanguage);

        // Construct the new URL with the updated parameter
        const newUrl = `${window.location.pathname}?${urlParams.toString()}${window.location.hash}`;

        // Update the browser URL without refreshing the page
        window.history.replaceState({}, '', newUrl);

        i18n.changeLanguage(newLanguage).then(r => {
        }).catch(e => {
            console.error(e);
        });
    };

    let containerClass = uiDirection === 'rtl' ? 'language-selector-container rtl' : 'language-selector-container';
    return (
        <div className={containerClass} style={{ 'display': langPickerParam ? '' : 'none'}}>
            <FontAwesomeIcon
                icon={ faEarthAmericas }
                style={{ color: theme.footerTextColor }}
            />
            <select onChange={ changeLanguage } defaultValue={ getLocale() } data-testid="language-selector" style={{ color: theme.footerTextColor }}>
                <option value="en" style={{ color: '#000' }}>English</option>
                <option value="fr" style={{ color: '#000' }}>Français</option>
                <option value="es" style={{ color: '#000' }}>Español</option>
                <option value="ar" style={{ color: '#000' }}>عربي</option>
            </select>
        </div>
    )
}

export default LanguageSelector;
