export const isValidHex = ((str) => {
    if (str && !str.startsWith('#')) {
        str = '#' + str;
    }

    return /^#([0-9A-Fa-f]{3}){1,2}$/i.test(str);
});

export const setHex = ((str) => {
    if (str && !str.startsWith('#')) {
        str = '#' + str;
    }

    return str;
});

export const darkenHex = ((hexColor, factor = 0.8) => {
    // Remove the '#' if it's included in the input
    const hex = hexColor.replace(/^#/, '');

    // Convert hex to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Apply darkness to each component
    r = Math.round(r * factor);
    g = Math.round(g * factor);
    b = Math.round(b * factor);

    // Ensure values are in the valid range (0-255)
    r = Math.max(0, Math.min(255, r));
    g = Math.max(0, Math.min(255, g));
    b = Math.max(0, Math.min(255, b));

    // Convert RGB back to hex
    const darkenedHex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');

    return `#${darkenedHex}`;
});