import i18n from 'i18next';

export const getLocale = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const locale = params.get('l');
    const validLocales = i18n.languages;
    if (locale && validLocales && validLocales.includes(locale)) {
        return locale;
    }
    return 'en';
};

export const getUiDirection = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let language = params.get('l') ?? 'en'
    return language === 'ar' ? 'rtl' : 'ltr';
};

export default getLocale;
