import Link from '@mui/material/Link';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import '../style/PoweredBy.css'
import { getUiDirection } from "../helper/Locale";

const PoweredBy = () => {
    const { t } = useTranslation();
    const { theme }  = useSelector((store) => store.theme);
    const uiDirection = getUiDirection();

    const productUrl = 'https://cigotracker.com';

    let containerClass = uiDirection === 'rtl' ? 'powered-by-container rtl' : 'powered-by-container';
    return (
        <div className={containerClass}>
            <Link href={ productUrl } underline="none" target="_blank" id="powered-by-link" style={{ color: theme.footerTextColor }}>
                { t("powered_by") } <span id="company-name">Cigo</span>
            </Link>
        </div>
    );
}

export default PoweredBy;
