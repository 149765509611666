import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    formSubmitted: false,
    recaptchaToken: ''
};

const trackSlice = createSlice({
    name: 'track',
    initialState,
    reducers: {
        setFormSubmitted: (state) => {
            state.formSubmitted = true;
        },
        setRecaptchaToken: (state, { payload: { token } }) => {
            state.recaptchaToken = token;
        }
    }
});

export const { setFormSubmitted } = trackSlice.actions;
export const { setRecaptchaToken } = trackSlice.actions;

export default trackSlice.reducer;
